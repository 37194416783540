import { FC } from "react"
import React from "reactn"
import marked from "marked"
import { graphql } from "gatsby"
import PageHeader from "../components/PageHeader"
import Link from "../components/Link"
import { useNavigate } from "@reach/router"

interface TakkForInteressenPageTemplateProps {
  html: string
}

const TakkForInteressenPageTemplate: FC<TakkForInteressenPageTemplateProps> = ({
  html
}) => {
  const navigate = useNavigate()
  return (
    <main
      id="takk-for-interessen-page"
      className="flex items-center justify-center text-center my-24 md:mt-12"
      style={{
        minHeight: "50vh"
      }}
    >
      <div className="mx-auto w-full max-w-md">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          xmlnsXlink="http://www.w3.org/1999/xlink"
          viewBox="0 0 426.667 426.667"
          className="w-24 mx-auto"
          style={{ fill: "#1544b3" }}
        >
          <path
            d="M213.333,0C95.518,0,0,95.514,0,213.333s95.518,213.333,213.333,213.333 c117.828,0,213.333-95.514,213.333-213.333S331.157,0,213.333,0z M174.199,322.918l-93.935-93.931l31.309-31.309l62.626,62.622 l140.894-140.898l31.309,31.309L174.199,322.918z"
          />
        </svg>

        <div
          className="markdown my-12"
          dangerouslySetInnerHTML={{
            __html: marked(html)
          }}
        />

        <div className="flex justify-center items-center sm:flex-col-reverse sm:mt-4">
          <button
            id="thanks-page_go-back_link"
            className="flex items-center mr-4 border-2 px-6 py-2 rounded-sm sm:mr-0 sm:mt-4"
            onClick={() => {
              navigate(-1)
            }}
          >
            <svg
              viewBox="0 0 24 24"
              width={20}
              height={20}
            >
              <path
                fill="currentColor"
                d="M13.83 19a1 1 0 0 1-.78-.37l-4.83-6a1 1 0 0 1 0-1.27l5-6a1 1 0 0 1 1.54 1.28L10.29 12l4.32 5.36a1 1 0 0 1-.78 1.64z"
              />
            </svg>

            <span>
              Gå tilbake
            </span>
          </button>

          <Link
            to="/prosjekter"
            className="mr-4 btn-ghost sm:mr-0"
          >
            Utforsk mer
          </Link>
        </div>
      </div>
    </main>
  )
}

interface TakkForInteressenPageProps {
  data: {
    markdownRemark: {
      html: string
    }
  }
}

const TakkForInteressenPage: React.FC<TakkForInteressenPageProps> = ({ data }) => {
  const { html } = data.markdownRemark
  return (
    <>
      <PageHeader />

      <TakkForInteressenPageTemplate
        html={html}
      />
    </>
  )
}

export default TakkForInteressenPage

export const TakkForInteressenPageQuery = graphql`
  query TakkForInteressenPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
    }
  }
`
